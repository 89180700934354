export const currency = (number: number | string, decimals = 2) => {
  const num = typeof number === 'string' ? parseFloat(number) : number

  return num.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    style: 'currency',
    currency: 'USD',
  })
}

export const prettyNum = (number: number | string) => {
  const num = typeof number === 'string' ? parseInt(number, 10) : number

  return num.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  })
}

export const hashCode = (str: string) => {
  // java String#hashCode
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase()

  return '00000'.substring(0, 6 - c.length) + c
}

export function updateClipboard(newClip: string) {
  navigator.clipboard.writeText(newClip).then(
    () => {
      /* clipboard successfully set */
      alert('copied')
    },
    () => {
      /* clipboard write failed */
      alert('failed')
    },
  )
}
