import {
  AddIcon,
  AddToArtifactIcon,
  AirplaneIcon,
  BanCircleIcon,
  GitCommitIcon,
  Heading,
  ListItem,
  PulseIcon,
  ResolveIcon,
  Table,
  TrendingDownIcon,
  TrendingUpIcon,
  UnorderedList,
} from 'evergreen-ui'
import React, { FC } from 'react'
import { currency, prettyNum } from '../utils'
import { DataSource } from '../lib/DataSource'
import { Periods } from '../lib/Periods'
import { ViewType } from '../App'

export const SummaryHeader: FC<{ src: DataSource; viewType: ViewType; revenueType: string; showEfrar: boolean }> = ({
  src,
  viewType,
  revenueType,
  showEfrar,
}) => {
  const range = Periods.range()

  return (
    <Table.Row height="auto" background="#F1F7FC" paddingY={8}>
      <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} />
      <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} />
      <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0} />

      <Table.TextCell />
      <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} />
      {range.map((date) => {
        const a = src.summarizeColumn(date)
        return (
          <Table.TextCell>
            <Heading size={200}>
              {viewType === 'recurring' ? (revenueType === 'monthly' ? 'MRR' : 'ARR') : 'Revenue'}
            </Heading>
            <UnorderedList size={300}>
              {showEfrar && (
                <ListItem icon={AirplaneIcon} iconColor="muted" title="EFRAR">
                  {currency(a.efrar.sum, 0)}
                </ListItem>
              )}
              <ListItem icon={AddToArtifactIcon} iconColor="muted">
                {currency(a.revenue.sum, 0)}
              </ListItem>
              <ListItem icon={GitCommitIcon} iconColor="muted">
                {currency(a.revenue.average, 0)}
              </ListItem>
              <ListItem icon={ResolveIcon} iconColor="muted">
                {currency(a.revenue.median, 0)}
              </ListItem>
              <ListItem icon={AddIcon} iconColor="muted">
                {currency(a.revenue.added, 0)}
              </ListItem>
              <ListItem icon={BanCircleIcon} iconColor="muted">
                {currency(a.revenue.churned, 0)}
              </ListItem>
              <ListItem icon={TrendingDownIcon} iconColor="muted">
                {currency(a.revenue.contractions, 0)}
              </ListItem>
              <ListItem icon={TrendingUpIcon} iconColor="muted">
                {currency(a.revenue.expansions, 0)}
              </ListItem>
            </UnorderedList>
            <Heading size={200}>Imports</Heading>
            <UnorderedList size={300}>
              <ListItem icon={AddToArtifactIcon} iconColor="muted">
                {prettyNum(a.imports.sum)}
              </ListItem>
              <ListItem icon={GitCommitIcon} iconColor="muted">
                {prettyNum(a.imports.average)}
              </ListItem>
              <ListItem icon={ResolveIcon} iconColor="muted">
                {prettyNum(a.imports.median)}
              </ListItem>
            </UnorderedList>
            <Heading size={200}>Customers</Heading>
            <UnorderedList size={300}>
              <ListItem icon={AddToArtifactIcon} iconColor="muted">
                {prettyNum(a.customers.current)}
              </ListItem>
              <ListItem icon={BanCircleIcon} iconColor="muted">
                {prettyNum(a.customers.churns)}
              </ListItem>
              <ListItem icon={AddIcon} iconColor="muted">
                {prettyNum(a.customers.firsts)}
              </ListItem>
              <ListItem icon={PulseIcon} iconColor="muted">
                {prettyNum(a.customers.actives)}
              </ListItem>
            </UnorderedList>
          </Table.TextCell>
        )
      })}
    </Table.Row>
  )
}
