import { calcMean } from 'math-helper-functions'
import { parseISO } from 'date-fns'
import arraySort from 'array-sort'
import unique from 'array-unique'
import { ViewType } from '../App'
import { CustomerNode, CustomerPoint } from './CustomerNode'
import deals from '../data/deals/results-20210326-225422.json'
import { Periods } from './Periods'

export class Customer {
  private first: CustomerNode

  private readonly data: CustomerNode[]

  public deals: any[]

  constructor(public teamId: string, data: CustomerPoint[]) {
    const up = unique(data.map((d) => d.period)).sort()
    const lastPeriod = up[up.length - 1]
    const firstPeriod = up[0]
    this.data = arraySort(data, 'period', { reverse: false }).map(
      (d) => new CustomerNode(d, d.period === firstPeriod, d.period === lastPeriod),
    )
    this.first = this.data[0]
    this.deals = deals.filter((d) => d.team_id === this.first.teamId.toString())
  }

  getColumnValue(key: keyof CustomerNode, period?: Date, viewType?: ViewType, revenueType?: string): any {
    const items = period ? this.getNodes(period) : [this.last]
    if (!items.length) return null

    if (key === 'revenue') {
      return this.convertMonthly(
        items.reduce((acc, item) => acc + item.revenue(viewType || 'all'), 0),
        revenueType,
      )
    }
    return items[0][key]
  }

  getNetExpansion(period: Date, revenueType?: string) {
    const prevPeriod = Periods.prevPeriod(period)
    const currRev = this.getColumnValue('revenue', period, 'recurring', revenueType)
    const prevRev = this.getColumnValue('revenue', prevPeriod, 'recurring', revenueType)
    if (!prevRev && currRev > 0 && this.getNode(period)?.isFirst) {
      return 0
    }
    if (!currRev && prevRev > 0 && this.status === 'cancelled' && this.getNode(prevPeriod)?.isLast) {
      return 0
    }
    if (!currRev && !prevRev) {
      return 0
    }

    return (currRev || 0) - (prevRev || 0)
  }

  getNode(period: Date): CustomerNode | undefined {
    const prevPeriod = Periods.prevPeriod(period)

    const node = this.data.find((c) => Periods.equal(c.period, period))
    if (
      this.status === 'active' &&
      !node &&
      Periods.equal(prevPeriod, this.last.period) &&
      Periods.equal(period, Periods.currentPeriod())
    ) {
      return this.last.shadow
    }
    return node
  }

  getNodes(period: Date): CustomerNode[] {
    const prevPeriod = Periods.prevPeriod(period)

    const nodes = this.data.filter((c) => Periods.equal(c.period, period))
    if (
      this.status === 'active' &&
      !nodes.length &&
      Periods.equal(prevPeriod, this.last.period) &&
      Periods.equal(period, Periods.currentPeriod())
    ) {
      return [this.last.shadow]
    }
    return nodes
  }

  getDealForPeriod(period: Date): any | undefined {
    return this.deals.find(
      (d) => parseISO(d.deal_created.split(' ')[0]) <= period && (!d.deal_closed || parseISO(d.deal_closed) >= period),
    )
  }

  get customerId(): string {
    return this.first.customerId
  }

  get teamName(): string {
    return this.first.teamName
  }

  get lifetimeUsage(): number {
    return this.data.reduce<number>((acc, p) => acc + p.usage, 0)
  }

  get last(): CustomerNode {
    return arraySort(this.data, 'period', { reverse: true })[0]
  }

  get status(): string {
    return this.last.subscriptionStatus
  }

  get planId(): string {
    return this.last.planId
  }

  wasActive(period: Date): boolean {
    if (this.last.period >= period && period >= this.first.period) {
      return true
    }

    if (period >= this.first.period && this.status === 'active') {
      return true
    }

    return false
  }

  get expansionStatus(): -1 | 1 | 0 {
    if (this.last.revenue('all') > this.first.revenue('all')) {
      return 1
    }
    if (this.last.revenue('all') < this.first.revenue('all')) {
      return -1
    }
    return 0
  }

  get usageExpansion(): -1 | 1 | 0 {
    const usages = this.data.map((n) => n.usage).filter((v) => v)
    const avg = calcMean(usages) || 0
    if (this.last.usage > avg) {
      return 1
    }
    if (this.last.usage < avg) {
      return -1
    }
    return 0
  }

  get atRisk(): boolean {
    return this.last.usage === 0
  }

  lifetimeRevenue(type: ViewType): number {
    return this.data.reduce<number>((acc, p) => acc + p.revenue(type), 0)
  }

  convertMonthly(num: number, revenueType?: string): number {
    if (revenueType === 'annual') {
      return num * 12
    }
    return num
  }
}
