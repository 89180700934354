import { add, eachMonthOfInterval, format, parseISO, sub } from 'date-fns'

export class Periods {
  /**
   * Return the acceptable Flatfile reporting range of dates
   */
  public static range(): Date[] {
    return eachMonthOfInterval({
      start: new Date(2019, 8, 1),
      end: new Date(),
    })
  }

  /**
   * Get the range as properly formatted string
   */
  public static rangeAsStrings(): string[] {
    return this.range().map((d) => this.formatPeriod(d))
  }

  /**
   * Return the period for today's date
   */
  public static currentPeriod(): Date {
    return this.getPeriod(new Date())
  }

  /**
   * Get the first of the month the provided date is in
   *
   * @param date
   */
  public static getPeriod(d: Date | string): Date {
    const date = this.normalizePeriod(d)
    return new Date(date.getFullYear(), date.getMonth(), 1)
  }

  /**
   * Compare to dates strictly (ignoring time)
   *
   * @param first
   * @param second
   */
  public static equal(first: Date, second: Date): boolean {
    return first.toLocaleDateString() === second.toLocaleDateString()
  }

  /**
   * Format a date into the shared ISO format
   *
   * @param date
   */
  public static formatPeriod(date: Date): string {
    return format(date, 'yyyy-MM-dd')
  }

  /**
   * Return the previous period for any date
   *
   * @param date
   */
  public static prevPeriod<T extends Date | string>(date: T): Date {
    const d = this.normalizePeriod(date)
    return this.getPeriod(sub(d, { months: 1 }))
  }

  /**
   * Return the next period for any date
   *
   * @param date
   */
  public static nextPeriod<T extends Date | string>(date: T): Date {
    const d = this.normalizePeriod(date)
    return this.getPeriod(add(d, { months: 1 }))
  }

  /**
   * Normalize a date or date string into a period Date
   *
   * @param date
   */
  public static normalizePeriod<T extends Date | string>(date: T): Date {
    if (date instanceof Date) {
      return date
    }
    if (typeof date === 'string') {
      return parseISO(date)
    }
    throw new Error('date was neither Date nor string')
  }
}
