import { calcMean, calcMedian, calcSum } from 'math-helper-functions'
import { Customer } from './Customer'
import { CustomerNode, CustomerPoint } from './CustomerNode'
import { updateClipboard } from '../utils'
import { Periods } from './Periods'

export class DataSource {
  public customers: Customer[] = []

  public sortBy: { key: keyof CustomerNode; period?: Date; direction: 0 | 1 } = { key: 'teamId', direction: 1 }

  public viewType: EViewType = EViewType.All

  public revenueType = 'monthly'

  constructor(public data: CustomerPoint[]) {
    const uniqueCustomers = this.data.reduce((acc, v) => {
      if (!v.team_id) return acc
      if (!acc[v.team_id]) {
        return { ...acc, [v.team_id]: [v] }
      }
      acc[v.team_id].push(v)
      return acc
    }, {} as { [team_id: string]: CustomerPoint[] })

    Object.keys(uniqueCustomers).forEach((teamId) => {
      this.customers.push(new Customer(teamId, uniqueCustomers[teamId]))
    })
  }

  getCustomers(): Customer[] {
    return this.customers.sort((a, b) => {
      const av = a.getColumnValue(this.sortBy.key, this.sortBy.period, this.viewType, this.revenueType)
      const bv = b.getColumnValue(this.sortBy.key, this.sortBy.period, this.viewType, this.revenueType)
      const l = this.sortBy.direction ? av : bv
      const r = this.sortBy.direction ? bv : av
      if (l < r) {
        return -1
      }
      if (l > r) {
        return 1
      }
      return 0
    })
  }

  sendToClipboard(withEfrar = false) {
    const range = Periods.range()
    const customers = this.getCustomers()
    const headers = ['ID', 'Name', 'Revenue', 'Usage', 'Plan', 'Status', ...Periods.rangeAsStrings()]
    const rows = customers.map((c) => {
      const values = range.map((d) =>
        withEfrar ? c.getColumnValue('efrar', d) : c.getColumnValue('revenue', d, this.viewType, this.revenueType),
      )
      return [c.teamId, c.teamName, c.lifetimeRevenue(this.viewType), c.lifetimeUsage, c.planId, c.status, ...values]
    })

    const output = [headers, ...rows]

    const tsv = output.map((row) => row.join('\t')).join('\r\n')
    updateClipboard(tsv)
  }

  summarizeColumn(period: Date) {
    const prevPeriod = Periods.prevPeriod(period)
    const values = this.customers
      .map((c) => c.getColumnValue('revenue', period, this.viewType, this.revenueType))
      .filter((v) => v && v > 0)

    const efrar = this.customers.map((c) => c.getColumnValue('efrar', period, this.viewType)).filter((v) => v && v > 0)

    const imports = this.customers.map((c) => c.getColumnValue('usageInPeriod', period)).filter((v) => v && v > 0)
    const sum = calcSum(values) || 0
    const average = calcMean(values) || 0
    const median = calcMedian(values) || 0

    const current = this.customers.map((c) => c.wasActive(period)).filter((v) => v).length
    const firsts = this.customers.map((c) => c.getNode(period)?.isFirst).filter((v) => v).length
    const churns = this.customers.map((c) => c.status === 'cancelled' && c.getNode(prevPeriod)?.isLast).filter((v) => v)
      .length
    const actives = this.customers.map((c) => (c.getNode(period)?.usageInPeriod ?? 0) > 0).filter((v) => v).length

    const churnedRevenue = this.customers
      .filter((c) => c.status === 'cancelled' && c.getNode(prevPeriod)?.isLast)
      .map((n) => n.getColumnValue('revenue', prevPeriod, this.viewType, this.revenueType))
    const newRevenue = this.customers
      .filter((c) => c.getNode(period)?.isFirst)
      .map((n) => n.getColumnValue('revenue', period, this.viewType, this.revenueType))

    const netExpansions = this.customers.map((c) => c.getNetExpansion(period, this.revenueType))
    if (Periods.equal(Periods.getPeriod('2021-01-01'), period)) {
      console.log(
        'NET EXPANSIONS',
        this.customers.filter((c) => c.getNetExpansion(period, this.revenueType)),
        this.customers
          .filter((c) => c.getNetExpansion(period, this.revenueType) < 0)
          .map((c) => ({ name: c.teamName, exp: c.getNetExpansion(period, this.revenueType) })),
      )
    }

    return {
      efrar: {
        sum: calcSum(efrar) || 0,
      },
      revenue: {
        average,
        sum,
        median,
        churned: calcSum(churnedRevenue) || 0,
        added: calcSum(newRevenue) || 0,
        contractions: calcSum(netExpansions.filter((v) => v < 0)),
        expansions: calcSum(netExpansions.filter((v) => v > 0)),
      },
      imports: {
        sum: calcSum(imports) || 0,
        average: calcMean(imports) || 0,
        median: calcMedian(imports) || 0,
      },
      customers: {
        current,
        firsts,
        churns,
        actives,
      },
    }
  }

  /**
   * get a specific period for a customer
   * @param id
   * @param date
   */
  getPoint(id: string, date: string) {
    return this.data.filter((v) => v.period === date && v.team_id === id.toString())[0]
  }

  /**
   * Get a specific customer from the data
   * @param id
   */
  getCustomer(id: string) {
    return this.data.find((v) => v.team_id === id.toString())
  }
}

export enum EViewType {
  OneTime = 'one-time',
  All = 'all',
  Usage = 'usage',
  Recurring = 'recurring',
}

export enum EDataType {
  Annual = 'annual',
  Monthly = 'monthly',
}
