import {
  Badge,
  BanCircleIcon,
  BankAccountIcon,
  CircleIcon,
  Code,
  FunctionIcon,
  HistoryIcon,
  Icon,
  IconButton,
  Link,
  Menu,
  MoreIcon,
  Popover,
  Position,
  ShopIcon,
  Small,
  Table,
  TickCircleIcon,
  TrendingDownIcon,
  TrendingUpIcon,
  WarningSignIcon,
} from 'evergreen-ui'
import Color from 'color'
import React, { FC } from 'react'
import { currency, prettyNum } from '../utils'
import { Customer } from '../lib/Customer'
import { ViewType } from '../App'

export const CustomerRecord: FC<{
  range: Date[]
  viewType: ViewType
  revenueType: string
  showDeals: boolean
  showEfrar: boolean
  customer: Customer
}> = ({ range, viewType, revenueType, showEfrar, customer, showDeals }) => {
  const c = customer

  return (
    <Table.Row key={c.teamId} height={32}>
      <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
        <Badge color="neutral">{c.teamId}</Badge>
      </Table.TextCell>
      <Table.TextCell
        flexBasis={250}
        flexShrink={0}
        flexGrow={0}
        rightView={
          <Popover
            content={
              <Menu>
                <Menu.Group>
                  <Menu.Item
                    icon={ShopIcon}
                    onSelect={(e) => {
                      window.open(
                        `https://flatfile.freshsales.io/accounts/view/custom?per_page=25&q[]={"name":"name","operator":17,"value":["${c.teamName}"]}`,
                        '_blank',
                      )
                    }}
                  >
                    Open in Freshsales
                  </Menu.Item>
                  <Menu.Item
                    icon={BankAccountIcon}
                    onSelect={(e) => {
                      alert('not setup yet')
                    }}
                  >
                    Open in Chargebee
                  </Menu.Item>
                </Menu.Group>
                <Menu.Divider />
                <Menu.Group>
                  <Menu.Item disabled>
                    <Code size={300}>{c.customerId}</Code>
                  </Menu.Item>
                </Menu.Group>
              </Menu>
            }
            position={Position.BOTTOM_RIGHT}
          >
            <IconButton icon={MoreIcon} height={24} appearance="minimal" />
          </Popover>
        }
      >
        <Icon
          icon={
            c.status === 'active'
              ? TickCircleIcon
              : c.status === 'non_renewing'
              ? WarningSignIcon
              : c.status === 'cancelled'
              ? BanCircleIcon
              : CircleIcon
          }
          color={
            c.status === 'active'
              ? 'success'
              : c.status === 'non_renewing'
              ? 'warning'
              : c.status === 'cancelled'
              ? 'danger'
              : 'info'
          }
          size={12}
          marginRight={8}
        />
        {c.teamName}
      </Table.TextCell>
      <Table.TextCell
        flexBasis={120}
        flexShrink={0}
        flexGrow={0}
        rightView={
          <Icon
            icon={
              c.status !== 'active' ? (
                <></>
              ) : c.expansionStatus === 1 ? (
                TrendingUpIcon
              ) : c.expansionStatus === -1 ? (
                TrendingDownIcon
              ) : (
                <></>
              )
            }
            color={c.expansionStatus === 1 ? 'success' : c.expansionStatus === -1 ? 'danger' : 'muted'}
          />
        }
      >
        {currency(c.lifetimeRevenue(viewType))}
      </Table.TextCell>
      <Table.TextCell
        rightView={
          <Icon
            size={12}
            icon={
              c.status !== 'active' ? (
                <></>
              ) : c.atRisk ? (
                WarningSignIcon
              ) : c.usageExpansion === 1 ? (
                TrendingUpIcon
              ) : c.usageExpansion === -1 ? (
                TrendingDownIcon
              ) : (
                <></>
              )
            }
            color={
              c.atRisk ? 'warning' : c.usageExpansion === 1 ? 'success' : c.usageExpansion === -1 ? 'danger' : 'muted'
            }
          />
        }
      >
        {prettyNum(c.lifetimeUsage)}
      </Table.TextCell>
      <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
        <Small color="muted">{c.planId}</Small>
      </Table.TextCell>
      {range.map((d) => {
        const n = c.getNode(d)
        const deal = c.getDealForPeriod(d)
        const dealBg = deal
          ? deal.deal_stage === 'Won'
            ? '#F1FAF5'
            : deal.deal_stage === 'Lost'
            ? '#FEF6F6'
            : '#FEF8E7'
          : 'transparent'
        if (!n)
          return (
            <Table.TextCell
              style={{
                background: showDeals ? dealBg : 'transparent',
              }}
            >
              -
            </Table.TextCell>
          )
        return (
          <Table.TextCell
            style={{
              background: showDeals ? dealBg : 'transparent',

              ...(n.invoiceStatus === 'not_paid'
                ? {
                    background: `repeating-linear-gradient(
                      45deg,
                      ${dealBg},
                      ${dealBg} 4px,
                      ${Color(dealBg === 'transparent' ? '#ffffff' : dealBg).darken(0.05)} 4px,
                      ${Color(dealBg === 'transparent' ? '#ffffff' : dealBg).darken(0.05)} 8px
                    )`,
                  }
                : {}),
            }}
            rightView={
              <>
                {n.isShadow ? (
                  <HistoryIcon color="muted" size={11} title="Shadow value. Not yet billed" />
                ) : n.isLast && c.status !== 'active' ? (
                  c.status === 'cancelled' ? (
                    <BanCircleIcon color="danger" size={11} title="Churned" />
                  ) : (
                    <WarningSignIcon color="warning" size={11} title="Not renewing" />
                  )
                ) : n.isAmortized ? (
                  <FunctionIcon color="muted" size={10} title="Amortized from multi-month invoice" />
                ) : undefined}
              </>
            }
          >
            <Link
              size={300}
              href={`https://flatfile.chargebee.com/d/invoices/${n.invoiceId}`}
              target="_blank"
              style={{
                textDecoration: 'none',

                ...(n.usage === 0 &&
                  c.status === 'active' &&
                  !c.planId?.includes('enterprise') &&
                  !c.planId?.includes('2_year') && {
                    background: '#faff47',
                    padding: '2px',
                    color: '#7E6514',
                  }),
                ...(n.isLast && c.status !== 'active'
                  ? { color: c.status === 'cancelled' ? '#EC4C47' : '#D9822B', fontWeight: 'bold' }
                  : {}),
                ...(n.isShadow && {
                  background: 'transparent',
                  color: '#A6B1BB',
                  padding: '2px',
                  borderRadius: '2px',
                }),
              }}
            >
              {currency(
                showEfrar && viewType === 'recurring' && revenueType === 'annual'
                  ? n.efrar || 0
                  : c.getColumnValue('revenue', d, viewType, revenueType),
              )}
            </Link>
          </Table.TextCell>
        )
      })}
    </Table.Row>
  )
}
