import React, { useState } from 'react'
import './App.css'
import { Button, Checkbox, ClipboardIcon, Pane, SegmentedControl } from 'evergreen-ui'
import GitHubButton from 'react-github-btn'
import data from './data/invoices/main.json'
import { DataTable } from './components/DataTable'
import { DataSource, EDataType, EViewType } from './lib/DataSource'
import { CustomerPoint } from './lib/CustomerNode'

const src = new DataSource(data as CustomerPoint[])

function App() {
  const [viewType, setViewType] = useState<EViewType>(EViewType.Recurring)
  const [dataType, setDataType] = useState<EDataType>(EDataType.Annual)
  const [dealHighlights, setDealHighlights] = useState<boolean>(true)
  const [efrar, setEfrar] = useState<boolean>(true)

  const options = [
    { label: 'All Revenue', value: EViewType.All },
    { label: 'Recurring', value: EViewType.Recurring },
    { label: 'Usage', value: EViewType.Usage },
    { label: 'Onetime', value: EViewType.Usage },
  ]

  const annualizedOptions = [
    { label: 'Annualized', value: 'annual' },
    { label: 'Monthly', value: 'monthly' },
  ]

  return (
    <Pane is="section" background="tint2" className="App" padding={16}>
      <Pane display="flex">
        <Pane flex={2} alignItems="center" display="flex">
          <SegmentedControl
            width={400}
            options={options}
            value={viewType}
            onChange={(value) => {
              src.viewType = value as EViewType
              setViewType(value as EViewType)
            }}
          />
        </Pane>
        <Pane flex={1} display="flex">
          <SegmentedControl
            width={220}
            options={annualizedOptions}
            value={dataType}
            marginX={16}
            onChange={(value) => {
              src.revenueType = value as EDataType
              setDataType(value as EDataType)
            }}
          />
        </Pane>
        <Pane flex={6} alignItems="center" display="flex">
          <Pane marginRight={16}>
            <Checkbox
              checked={dealHighlights}
              label="Show Deal Highlights"
              onChange={(e) => setDealHighlights(e.target.checked)}
              margin={0}
            />
          </Pane>
          {viewType === 'recurring' && dataType === 'annual' && (
            <Pane>
              <Checkbox checked={efrar} label="Show EFRAR" onChange={(e) => setEfrar(e.target.checked)} margin={0} />
            </Pane>
          )}
        </Pane>
        <Pane alignItems="center" display="flex">
          <GitHubButton
            href="https://github.com/Flatfilers/Money/issues"
            aria-label="Flatfilers/Money on GitHub"
            data-size="large"
          >
            Request Feature
          </GitHubButton>
          <Button
            iconBefore={ClipboardIcon}
            marginLeft={16}
            onClick={() => {
              src.sendToClipboard(efrar)
            }}
          >
            Copy Data
          </Button>
        </Pane>
      </Pane>
      <DataTable src={src} viewType={viewType} revenueType={dataType} showDeals={dealHighlights} showEfrar={efrar} />
    </Pane>
  )
}
export type ViewType = 'one-time' | 'all' | 'usage' | 'recurring'
export default App
