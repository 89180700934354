import React, { FC } from 'react'
import { format } from 'date-fns'
import { Icon, Pane, SortNumericalDescIcon, Table } from 'evergreen-ui'
import { DataSource } from '../lib/DataSource'
import { ViewType } from '../App'
import { Periods } from '../lib/Periods'
import { SummaryHeader } from './SummaryHeader'
import { CustomerRecord } from './CustomerRecord'

export const DataTable: FC<{
  src: DataSource
  viewType: ViewType
  revenueType: string
  showDeals: boolean
  showEfrar: boolean
}> = ({ src, viewType, revenueType, showDeals, showEfrar }) => {
  const range = Periods.range()
  const customers = src.getCustomers()
  return (
    <Pane border background="white" marginTop={16} padding={4} elevation={3} borderRadius={4} overflow="auto">
      <Table minWidth={3000}>
        <Table.Head>
          <Table.TextHeaderCell flexBasis={80} flexShrink={0} flexGrow={0}>
            ID
          </Table.TextHeaderCell>
          <Table.SearchHeaderCell flexBasis={250} flexShrink={0} flexGrow={0}>
            Name
          </Table.SearchHeaderCell>
          <Table.TextHeaderCell flexBasis={120} flexShrink={0} flexGrow={0}>
            Revenue
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>Usage</Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={200} flexShrink={0} flexGrow={0}>
            Plan
          </Table.TextHeaderCell>
          {range.map((d) => (
            <Table.TextHeaderCell rightView={<Icon icon={SortNumericalDescIcon} size={12} />}>
              {format(d, 'LLL yy')}
            </Table.TextHeaderCell>
          ))}
        </Table.Head>
        <Table.Body>
          <SummaryHeader revenueType={revenueType} showEfrar={showEfrar} src={src} viewType={viewType} />
          {customers.map((c) => (
            <CustomerRecord
              range={range}
              customer={c}
              revenueType={revenueType}
              showEfrar={showEfrar}
              viewType={viewType}
              showDeals={showDeals}
            />
          ))}
        </Table.Body>
      </Table>
    </Pane>
  )
}
