import { ViewType } from '../App'
import { Periods } from './Periods'

export class CustomerNode {
  constructor(
    private readonly point: CustomerPoint,
    public readonly isFirst: boolean,
    public readonly isLast: boolean,
    public readonly isShadow: boolean = false,
  ) {}

  get period(): Date {
    return Periods.getPeriod(this.point.period)
  }

  get customerId(): string {
    return this.point.customer_id || 'n/a'
  }

  public revenue(type: ViewType): number {
    switch (type) {
      case 'all':
        return this.revenue('one-time') + this.revenue('recurring') + this.revenue('usage')
      case 'one-time':
        return parseFloat(this.point.revenue_onetime)
      case 'recurring':
        return parseFloat(this.point.revenue_recurring)
      case 'usage':
        return parseFloat(this.point.revenue_usage)
      default:
        return 0
    }
  }

  get teamName(): string {
    return this.point.team_name as string
  }

  get teamId(): number {
    return this.point.team_id ? parseInt(this.point.team_id, 10) : 0
  }

  get planId(): string {
    return this.point.plan_id as string
  }

  get invoiceId(): string {
    return this.point.invoice_id as string
  }

  get usage(): number {
    return parseInt(this.point.usage_in_period, 10)
  }

  get subscriptionStatus(): string {
    return this.point.subscription_status as string
  }

  get invoiceStatus(): string {
    return this.point.invoice_status as string
  }

  get isAmortized(): boolean {
    return this.point.is_amortized === 'true'
  }

  get subscriptionStarted(): string {
    return this.point.subscription_started as string
  }

  get subscriptionEnded(): string {
    return this.point.subscription_ended as string
  }

  get usageInPeriod(): number {
    return parseInt(this.point.usage_in_period, 10)
  }

  get shadow(): CustomerNode {
    return new CustomerNode(this.point, this.isFirst, this.isLast, true)
  }

  get efrar(): number | undefined {
    return this.point.efrar ? parseInt(this.point.efrar, 10) : undefined
  }

  get efrarNotes(): string | undefined {
    return this.point.efrar_notes
  }

  get efrarReason(): string | undefined {
    return this.point.efrar_reason
  }

  get hasEfrar(): boolean {
    return this.point.has_efrar === 'true'
  }
}

export interface CustomerPoint {
  period: string
  customer_id?: string
  revenue_onetime: string
  revenue_recurring: string
  revenue_usage: string
  efrar?: string
  efrar_notes?: string
  efrar_reason?: string
  has_efrar?: string
  team_id?: string
  team_name?: string
  usage_in_period: string
  is_amortized: string
  plan_id?: string
  invoice_id?: string
  invoice_status?: string
  subscription_status?: string
  subscription_started?: string
  subscription_ended?: string
}
